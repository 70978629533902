import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
const ContentEditPage = lazy(() => import("./ContentEditPage"));
const ContentPage = lazy(() => import("./ContentPage"));
const Main = lazy(() => import("./Main"));
const Welcome = lazy(() => import("./Welcome"));
const Quote = lazy(() => import("./customer/Quote"));

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <Switch>
          <Route path="/" exact component={Welcome} />
          <Route path="/p/:page" exact component={ContentPage} />
          <Route path="/p/:page/edit" exact component={ContentEditPage} />
          <Route path="/:tenant/q/:id" exact component={Quote} />
          <Route path="/:tenant" component={Main} />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
