import React from "react";
import { compose } from "recompose";
import withFirebase from "./withFirebase";

function withAuthentication(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.auth = props.firebase.auth;
      this.state = {
        loading: true,
        permissions: []
      };
    }

    componentDidMount() {
      this.auth.onAuthStateChanged(user => {
        if (user == null) {
          this.setState({
            loading: false,
            currentUser: user
          });
          return;
        }

        user.getIdTokenResult().then(result => {
          this.setState({
            loading: false,
            currentUser: user,
            tenant: result.claims.tenant,
            brands: result.claims.brands,
            permissions: result.claims.permissions,
            tradeClients: result.claims.tradeClients,
          });
        });
      });
    }

    render() {
      return this.state.loading ? (
        <div />
      ) : (
        <WrappedComponent
          currentUser={this.state.currentUser}
          brands={this.state.brands}
          tenant={this.state.tenant}
          permissions={this.state.permissions}
          tradeClients={this.state.tradeClients}
          loadingAuth={this.state.loading}
          {...this.props}
        />
      );
    }
  };
}

export default compose(withFirebase, withAuthentication);
