import React from "react";
import FirebaseContext from "./FirebaseContext";

export default function withFirebase(WrappedComponent) {
  return function (props) {
    return (
      <FirebaseContext.Consumer>
        {firebase => <WrappedComponent firebase={firebase} {...props} />}
      </FirebaseContext.Consumer>
    );
  };
}
