import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthentication from "./withAuthentication";
import withFirebase from "./withFirebase";

function requireAuthentication(WrappedComponent) {
  return function(props) {
    if (props.loadingAuth) {
      return <div />;
    }

    if (props.currentUser == null) {
      return <Redirect to="/" />;
    }

    if (props.match.params.tenant !== props.tenant) {
      return <Redirect to="/unauthorized" />;
    }

    return <WrappedComponent {...props} />;
  };
}

export default compose(
  withFirebase,
  withAuthentication,
  withRouter,
  requireAuthentication
);
