import React from "react";

import { compose } from "recompose";

import requireAuthentication from "./requireAuthentication";

const INITIAL_STATE = {
  document: null,
  snapshot: null
};

function withDocument(path) {
  const withDocumentHOC = WrappedComponent => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.path = typeof path === "function" ? path(this.props) : path || "";
      }

      componentDidMount() {
        const document = this.props.firebase.doc(this.path);
        this.setState({ document });
        this.unsubscribe = document.onSnapshot(snapshot =>
          this.setState({ snapshot })
        );
      }

      componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
      }

      render() {
        return (
          <WrappedComponent
            document={this.state.document}
            snapshot={this.state.snapshot}
            {...this.props}
          />
        );
      }
    };
  };

  return compose(
    requireAuthentication,
    withDocumentHOC
  );
}

export default withDocument;
