import FirebaseContext from './FirebaseContext';
import Firebase from './Firebase';

export default Firebase;

export { FirebaseContext };
export { default as requireAuthentication } from './requireAuthentication';
export { default as withAuthentication } from './withAuthentication';
export { default as withCollection } from './withCollection';
export { default as withDocument } from './withDocument';
export { default as withFirebase } from './withFirebase';