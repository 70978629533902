import React from "react";

import { compose } from "recompose";

import requireAuthentication from "./requireAuthentication";

const INITIAL_STATE = {
  collection: null,
  snapshot: null
};

function withCollection(path, orderBy, whereClause) {
  const withCollectionHOC = WrappedComponent => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.path = typeof path === "function" ? path(this.props) : path;
        this.whereClause =
          typeof whereClause === "function"
            ? whereClause(this.props)
            : whereClause;
      }

      componentDidMount() {
        const collection = this.props.firebase.collection(this.path);
        let query = collection;
        if (orderBy) {
          if (Array.isArray(orderBy)) {
            query = query.orderBy(orderBy[0], orderBy[1]);
          } else {
            query = query.orderBy(orderBy);
          }
        }

        const whereClause = this.whereClause;
        if (whereClause) {
          query = query.where(
            whereClause.fieldName,
            whereClause.operator,
            whereClause.value
          );
        }

        this.setState({ collection, query });
        this.unsubscribe = (query ? query : query).onSnapshot(snapshot => {
          this.setState({ snapshot });
        });
      }

      componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
      }

      render() {
        return (
          <WrappedComponent
            collection={this.state.collection}
            query={this.state.query}
            snapshot={this.state.snapshot}
            {...this.props}
          />
        );
      }
    };
  };

  return compose(requireAuthentication, withCollectionHOC);
}

export default withCollection;
