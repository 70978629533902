import app from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.firestore = app.firestore();
    app.analytics();
    //app.functions().useFunctionsEmulator('http://localhost:5000');
    this.storage = app.storage();
    this.host = window.location.hostname.split('.')[0];
  }

  async signInWithCustomToken(token) {
    await this.auth.signInWithCustomToken(token);
  }

  signOut() {
    return this.auth.signOut();
  }

  collection(path) {
    return this.firestore.collection(path);
  }

  doc(path) {
    return this.firestore.doc(path);
  }

  ref(path) {
    return this.storage.ref(path);
  }
}

export default Firebase;
